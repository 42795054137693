import { Row, Col } from "antd";
import { withTranslation } from "react-i18next";
import { SvgIcon } from "../../common/SvgIcon";
import Container from "../../common/Container";

import i18n from "i18next";
import {
  FooterSection,
  Title,
  NavLink,
  Extra,
  LogoContainer,
  Para,
  Large,
  Chat,
  Empty,
  FooterContainer,
  Language,
  Label,
  LanguageSwitch,
  LanguageSwitchContainer,
} from "./styles";

interface SocialLinkProps {
  href: string;
  src: string;
}

const Footer = ({ t }: any) => {
  const handleChange = (language: string) => {
    i18n.changeLanguage(language);
  };

  const SocialLink = ({ href, src }: SocialLinkProps) => {
    return (
      <a
        href={href}
        target="_blank"
        rel="noopener noreferrer"
        key={src}
        aria-label={src}
      >
        <SvgIcon src={src} width="25px" height="25px" />
      </a>
    );
  };

  function getYear() {
    return (new Date()).getFullYear();
  }

  return (
    <>
      <FooterSection
        id="contact"
      >
        <Container border={false}>
          <Row
            justify="center"
            align="middle"
            style={{ paddingTop: "1rem" }}
          >
            <div style={{'textAlign' : 'center'}}>
              <p>
                Máte záujem nás kontaktovať?
              </p>
              <p>
                Napíšte nám na  <a href={'mailto:info@ludialudom.sk'} style={{color: '#fff', fontWeight: 'bold'}} rel="nofollow">info@ludialudom.sk</a>
              </p>
            </div>
          </Row>
          <Row
            justify="center"
            align="middle"
            style={{ paddingTop: "1rem" }}
          >
            <FooterContainer>
              <SocialLink
                href="https://www.facebook.com/ludialudom/"
                src="facebook.svg"
              />
              <SocialLink
                href="https://sk.linkedin.com/company/ludialudomsk"
                src="linkedin.svg"
              />
              <SocialLink
                href="https://www.instagram.com/ludialudom.sk/"
                src="instagram.svg"
              />
            </FooterContainer>
          </Row>
          <Row style={{'margin': '2rem 0 1rem 0'}}>
            <NavLink to="/" style={{'margin': '1rem auto'}}>
              <LogoContainer>
                <SvgIcon
                  src="logo.svg"
                  aria-label="homepage"
                  width="101px"
                  height="64px"
                />
              </LogoContainer>
            </NavLink>
          </Row>
          <Row justify="center">
            <div style={{color: '#fff', textAlign: 'center'}}>
              © {getYear()} ĽudiaĽuďom.sk. Všetky práva vyhradené.
            </div>
          </Row>
        </Container>
      </FooterSection>
    </>
  );
};

export default withTranslation()(Footer);
